import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { createGlobalStyle } from "styled-components";
import FadeIn from "react-fade-in";

// Global Styles
const WoffFont = createGlobalStyle`.${(props) =>
  props.font.font_class}{font-family: "${(props) => props.font.name}";}`;

const WoffPart = createGlobalStyle`.${(props) =>
  props.part.font_class}{font-family: "${(props) => props.part.name}";}`;

function FuquaVar({ font }) {
  // State Etc
  const [parts, setParts] = useState([]);
  const [chars, setChars] = useState([]);
  const [relations, setRelations] = useState([]);

  // Off Canvas
  const backdropShowOpt = false;
  const bodyScrollOpt = true;
  const placementOpt = "bottom";

  // useEffects
  useEffect(() => {
    setParts(
      font.parts.map((item) => ({
        ...item,
        b_wght: "400",
        b_wght_default: "400",
        b_wght_min: "100",
        b_wght_max: "1000",
        b_wght_step: "0.05",
        s_wght: "400",
        s_wght_default: "400",
        s_wght_min: "100",
        s_wght_max: "1000",
        s_wght_step: "0.05",
        b_alignment: "text-left",
        s_alignment: "text-center",
        overflow: true,
      }))
    );
  }, [font.parts]);

  useEffect(() => {
    setChars(
      font.chars.map((item) => ({
        ...item,
        b_wght: "400",
        b_wght_default: "400",
        b_wght_min: "100",
        b_wght_max: "1000",
        b_wght_step: "0.05",
        s_wght: "400",
        s_wght_default: "400",
        s_wght_min: "100",
        s_wght_max: "1000",
        s_wght_step: "0.05",
        b_alignment: "text-left",
        s_alignment: "text-center",
        overflow: true,
      }))
    );
  }, [font.chars]);

  useEffect(() => {
    setRelations(
      font.relations.map((item) => ({
        ...item,
      }))
    );
  }, [font.relations]);

  // Overflows
  const onRemoveOverflow = (i) => {
    setParts(
      parts.map((item, j) => (j !== i ? item : { ...item, overflow: false }))
    );
  };

  const onAddOverflow = (i) => {
    setParts(
      parts.map((item, j) => (j !== i ? item : { ...item, overflow: true }))
    );
  };

  const onRemoveOverflowChar = (i) => {
    setChars(
      chars.map((item, j) => (j !== i ? item : { ...item, overflow: false }))
    );
  };

  const onAddOverflowChar = (i) => {
    setChars(
      chars.map((item, j) => (j !== i ? item : { ...item, overflow: true }))
    );
  };

  // Tester Controls
  const onAddTextLeftBig = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_alignment: "text-left",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextLeftBigChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_alignment: "text-left",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextLeftSmall = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_alignment: "text-left",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextLeftSmallChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_alignment: "text-left",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextCenterBig = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_alignment: "text-center",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextCenterBigChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_alignment: "text-center",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextCenterSmall = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_alignment: "text-center",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextCenterSmallChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_alignment: "text-center",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextRightBig = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_alignment: "text-end",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextRightBigChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_alignment: "text-end",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const onAddTextRightSmall = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_alignment: "text-end",
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const onAddTextRightSmallChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_alignment: "text-end",
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  function onSizeChangeBig(i, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onSizeChangeBigChar(order, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onSizeChangeSmall(i, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onSizeChangeSmallChar(order, event) {
    const sizeValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_size: sizeValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onLineHeightChangeBig(i, event) {
    const lineHeightValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_lh: lineHeightValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onLineHeightChangeBigChar(order, event) {
    const lineHeightValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_lh: lineHeightValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onWghtChangeBig(i, event) {
    const wghtValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_wght: wghtValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onWghtChangeBigChar(i, event) {
    const wghtValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_wght: wghtValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onWghtChangeSmall(i, event) {
    const wghtValueUpdate = event.target.value;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_wght: wghtValueUpdate,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onWghtChangeSmallChar(i, event) {
    const wghtValueUpdate = event.target.value;
    const updatedData = chars.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_wght: wghtValueUpdate,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onTextChangeBig(i, event) {
    const newText = event.target.innerText;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          b_text: newText,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onTextChangeBigChar(order, event) {
    const newText = event.target.innerText;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          b_text: newText,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  function onTextChangeSmall(i, event) {
    const newText = event.target.innerText;
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          s_text: newText,
        };
      }
      return item;
    });
    setParts(updatedData);
  }

  function onTextChangeSmallChar(order, event) {
    const newText = event.target.innerText;
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          s_text: newText,
        };
      }
      return item;
    });
    setChars(updatedData);
  }

  const onApplyAllBig = useCallback(
    (i) => {
      const updatedPartData = parts.map((item, idx) => {
        if (idx !== i) {
          return {
            ...item,
            overflow: parts[i].overflow,
            b_alignment: parts[i].b_alignment,
            b_size: parts[i].b_size,
            b_lh: parts[i].b_lh,
            b_text: parts[i].b_text,
            b_wght: parts[i].b_wght,
          };
        }
        return item;
      });
      setParts(updatedPartData);
    },
    [parts]
  );

  const onApplyAllBigChar = useCallback(
    (order) => {
      const updatedData = parts.map((item) => {
        return {
          ...item,
          overflow: chars[order - 1].overflow,
          b_alignment: chars[order - 1].b_alignment,
          b_size: chars[order - 1].b_size,
          b_lh: chars[order - 1].b_lh,
          b_text: chars[order - 1].b_text,
          b_wght: chars[order - 1].b_wght,
        };
      });
      setParts(updatedData);
    },
    [parts, chars]
  );

  const onApplyAllSmall = useCallback(
    (i) => {
      const updatedPartData = parts.map((item, idx) => {
        if (idx !== i) {
          return {
            ...item,
            s_alignment: parts[i].s_alignment,
            s_size: parts[i].s_size,
            s_lh: parts[i].s_lh,
            s_text: parts[i].s_text,
            s_wght: parts[i].s_wght,
          };
        }
        return item;
      });
      setParts(updatedPartData);
    },
    [parts]
  );

  const onApplyAllSmallChar = useCallback(
    (order) => {
      const updatedData = parts.map((item) => {
        return {
          ...item,
          s_alignment: chars[order - 1].s_alignment,
          s_size: chars[order - 1].s_size,
          s_lh: chars[order - 1].s_lh,
          s_text: chars[order - 1].s_text,
          s_wght: chars[order - 1].s_wght,
        };
      });
      setParts(updatedData);
    },
    [parts, chars]
  );

  function onResetAllBig() {
    setParts(
      parts.map((item) => ({
        ...item,
        overflow: true,
        b_alignment: "text-left",
        b_size: item.b_size_default,
        b_lh: item.b_lh_default,
        b_wght: item.b_wght_default,
        b_text: item.b_text_default,
      }))
    );
    setChars(
      chars.map((item) => ({
        ...item,
        overflow: true,
        b_alignment: "text-left",
        b_size: item.b_size_default,
        b_lh: item.b_lh_default,
        b_wght: item.b_wght_default,
        b_text: item.b_text_default,
      }))
    );
  }

  function onResetAllSmall() {
    setParts(
      parts.map((item) => ({
        ...item,
        s_alignment: "text-center",
        s_size: item.s_size_default,
        s_lh: item.s_lh_default,
        s_wght: item.s_wght_default,
        s_text: item.s_text_default,
      }))
    );
    setChars(
      chars.map((item) => ({
        ...item,
        s_alignment: "text-center",
        s_size: item.s_size_default,
        s_lh: item.s_lh_default,
        s_wght: item.s_wght_default,
        s_text: item.s_text_default,
      }))
    );
  }

  const toggleShowOptPart = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          itemShowOptPart: !item.itemShowOptPart,
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const handleCloseOptPart = (i) => {
    const updatedData = parts.map((item, idx) => {
      if (idx === i) {
        return {
          ...item,
          itemShowOptPart: false,
        };
      }
      return item;
    });
    setParts(updatedData);
  };

  const toggleShowOptChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          itemShowOptChar: !item.itemShowOptChar,
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  const handleCloseOptChar = (order) => {
    const updatedData = chars.map((item, idx) => {
      if (idx + 1 === order) {
        return {
          ...item,
          itemShowOptChar: false,
        };
      }
      return item;
    });
    setChars(updatedData);
  };

  return (
    <>
      <FadeIn>
        <Container fluid id="tester" className="tester-contain">
          <div className="d-md-flex d-none justify-content-between mb-3">
            <Row className="pt-2">
              <Col md={12}>
                <p>
                  {parts.length} Variable Font(s) |{" "}
                  <i className="bi bi-cloud-arrow-down-fill"></i>{" "}
                  <a href={"/pdfs/spec/" + font.slug + "/" + font.pdf}>PDF</a>
                </p>
              </Col>
            </Row>
            {relations.length > 0 && (
              <>
                <Row>
                  <Col md={12}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={relations.length + " Related Font(s)"}
                      className="relation-button"
                    >
                      {relations
                        .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                        .map((relation, i) => (
                          <Dropdown.Item
                            key={i}
                            href={"/font/" + relation.slug}
                          >
                            {relation.name}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="d-block d-md-none text-center mb-4">
            <Row className="pt-2">
              <Col md={12}>
                <p>
                  {parts.length} Static Font(s) |{" "}
                  <i className="bi bi-cloud-arrow-down-fill"></i>{" "}
                  <a href={"/pdfs/spec/" + font.slug + "/" + font.pdf}>PDF</a>
                </p>
              </Col>
            </Row>
            {relations.length > 0 && (
              <>
                <Row>
                  <Col md={12}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={relations.length + " Related Font(s)"}
                      className="relation-button"
                    >
                      {relations
                        .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                        .map((relation, i) => (
                          <Dropdown.Item
                            key={i}
                            href={"/font/" + relation.slug}
                          >
                            {relation.name}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <WoffFont font={font} />
          {parts
            .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
            .map((part, i) => (
              <div key={i}>
                <WoffPart part={part} />
                <Row>
                  {/* Big Part */}
                  <Col className="unit-big d-md-block d-none" md={12}>
                    <form className="form">
                      <div className="d-flex tester-nav justify-content-between">
                        <div className="weight-unit d-flex">
                          <div className="weight">{part.name}</div>
                          <div className="size-unit">
                            <input
                              type="range"
                              name="size"
                              min={part.b_size_min}
                              max={part.b_size_max}
                              value={part.b_size}
                              step={part.b_size_step}
                              className="size form-range"
                              aria-label="Size"
                              onChange={(event) => onSizeChangeBig(i, event)}
                            ></input>
                          </div>
                          <div
                            className={
                              part.overflow
                                ? "line-height-unit d-none"
                                : "line-height-unit"
                            }
                          >
                            <input
                              type="range"
                              name="line-height"
                              min={part.b_lh_min}
                              max={part.b_lh_max}
                              value={part.b_lh}
                              step={part.b_lh_step}
                              className="line-height form-range"
                              aria-label="Line Height"
                              onChange={(event) =>
                                onLineHeightChangeBig(i, event)
                              }
                            ></input>
                          </div>
                          <div
                            className={
                              part.overflow ? "align-unit d-none" : "align-unit"
                            }
                          >
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-left"
                              onClick={() => onAddTextLeftBig(i)}
                            >
                              <i className="bi bi-text-left"></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-center"
                              onClick={() => onAddTextCenterBig(i)}
                            >
                              <i className="bi bi-text-center"></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-right"
                              onClick={() => onAddTextRightBig(i)}
                            >
                              <i className="bi bi-text-right"></i>
                            </button>
                          </div>
                          <div className="tools">
                            <button
                              type="button"
                              name="align"
                              className={
                                part.overflow
                                  ? "btn btn-link text-tools"
                                  : "btn btn-link text-tools d-none"
                              }
                              onClick={() => onRemoveOverflow(i)}
                            >
                              <i className="bi bi-tools"></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className={
                                part.overflow
                                  ? "btn btn-link text-arrows d-none"
                                  : "btn btn-link text-arrows"
                              }
                              onClick={() => onAddOverflow(i)}
                            >
                              <i className="bi bi-arrows-angle-contract"></i>
                            </button>
                          </div>
                        </div>
                        <div className="options-unit">
                          <button
                            type="button"
                            className="btn btn-link apply-all"
                            onClick={() => onApplyAllBig(i)}
                          >
                            Apply All
                          </button>
                          <button
                            type="button"
                            className="btn btn-link reset-all"
                            onClick={onResetAllBig}
                          >
                            Reset All
                          </button>
                        </div>
                      </div>
                      <div
                        className={
                          part.overflow
                            ? "weight-unit d-flex d-none"
                            : "weight-unit d-flex"
                        }
                      >
                        <div className="weight">Weight (wght)</div>
                        <div className="size-unit">
                          <input
                            type="range"
                            name="wght"
                            min={part.b_wght_min}
                            max={part.b_wght_max}
                            value={part.b_wght}
                            step={part.b_wght_step}
                            className="size form-range"
                            aria-label="Wght"
                            onChange={(event) => onWghtChangeBig(i, event)}
                          ></input>
                        </div>
                      </div>
                      <div
                        className="text-unit"
                        onClick={() => onRemoveOverflow(i)}
                        onKeyDown={() => onRemoveOverflow(i)}
                        role="none"
                      >
                        <p
                          contentEditable="true"
                          spellCheck="false"
                          suppressContentEditableWarning="true"
                          className={
                            part.overflow
                              ? "overflow " +
                                part.font_class +
                                " " +
                                part.b_alignment
                              : part.font_class + " " + part.b_alignment
                          }
                          style={{
                            fontSize: part.b_size + "em",
                            lineHeight: part.b_lh + "em",
                            fontVariantLigatures: "discretionary-ligatures",
                            fontVariationSettings: "'wght'" + part.b_wght,
                          }}
                          onBlur={(event) => onTextChangeBig(i, event)}
                          role="none"
                        >
                          {part.b_text}
                        </p>
                      </div>
                    </form>
                  </Col>
                  {/* Small Part */}
                  <Col className="unit-small d-md-none" md={12}>
                    <div className="tester-nav">
                      <div className="d-flex justify-content-between">
                        <div className="weight-unit">
                          <div className="weight">{part.name}</div>
                        </div>
                        <div className="options-unit">
                          <div>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => toggleShowOptPart(i)}
                            >
                              Options
                            </button>
                          </div>
                          <Offcanvas
                            show={part.itemShowOptPart}
                            onHide={() => handleCloseOptPart(i)}
                            backdrop={backdropShowOpt}
                            scroll={bodyScrollOpt}
                            placement={placementOpt}
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                {part.name} Options
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div>
                                <button
                                  type="button"
                                  name="align"
                                  className="btn btn-link text-left"
                                  onClick={() => onAddTextLeftSmall(i)}
                                >
                                  <i
                                    className="bi bi-text-left"
                                    style={{ fontSize: "2em" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  name="align"
                                  className="btn btn-link text-center"
                                  onClick={() => onAddTextCenterSmall(i)}
                                >
                                  <i
                                    className="bi bi-text-center"
                                    style={{ fontSize: "2em" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  name="align"
                                  className="btn btn-link text-right"
                                  onClick={() => onAddTextRightSmall(i)}
                                >
                                  <i
                                    className="bi bi-text-right"
                                    style={{ fontSize: "2em" }}
                                  ></i>
                                </button>
                              </div>
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-link apply-all"
                                  onClick={() => onApplyAllSmall(i)}
                                >
                                  Apply All
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link reset-all"
                                  onClick={onResetAllSmall}
                                >
                                  Reset All
                                </button>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        </div>
                      </div>
                      <div className="size-unit">
                        <input
                          type="range"
                          name="size"
                          min={part.s_size_min}
                          max={part.s_size_max}
                          value={part.s_size}
                          step={part.s_size_step}
                          className="size form-range"
                          aria-label="Size"
                          onChange={(event) => onSizeChangeSmall(i, event)}
                        ></input>
                      </div>
                      <div className="size-unit">
                        <input
                          type="range"
                          name="wght"
                          min={part.s_wght_min}
                          max={part.s_wght_max}
                          value={part.s_wght}
                          step={part.s_wght_step}
                          className="size form-range"
                          aria-label="Wght"
                          onChange={(event) => onWghtChangeSmall(i, event)}
                        ></input>
                      </div>
                    </div>
                    <div className="text-unit">
                      <p
                        contentEditable="true"
                        spellCheck="false"
                        suppressContentEditableWarning="true"
                        className={part.font_class + " " + part.s_alignment}
                        style={{
                          fontSize: part.s_size + "em",
                          lineHeight: part.s_lh + "em",
                          whiteSpace: "pre-line",
                          fontVariantLigatures: "discretionary-ligatures",
                          fontVariationSettings: "'wght'" + part.s_wght,
                        }}
                        onBlur={(event) => onTextChangeSmall(i, event)}
                        role="none"
                      >
                        {part.s_text}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          {/* Chars */}
          {chars
            .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
            // .filter((char) => char.sequence === count)
            .map((fChar, i) => (
              <div key={i}>
                <Row>
                  {/* Big Char */}
                  <Col
                    className="unit-big char-border d-md-block d-none"
                    md={12}
                  >
                    <form className="form">
                      <div className="d-flex tester-nav justify-content-between">
                        <div className="weight-unit d-flex">
                          <div className="system">Basic Character Set</div>
                          <div className="size-unit">
                            <input
                              type="range"
                              name="wght"
                              min={fChar.b_size_min}
                              max={fChar.b_size_max}
                              value={fChar.b_size}
                              step={fChar.b_size_step}
                              className="size form-range"
                              aria-label="Size"
                              onChange={(event) =>
                                onSizeChangeBigChar(fChar.sequence, event)
                              }
                            ></input>
                          </div>
                          <div
                            className={
                              fChar.overflow
                                ? "line-height-unit d-none"
                                : "line-height-unit"
                            }
                          >
                            <input
                              type="range"
                              name="line-height"
                              min={fChar.b_lh_min}
                              max={fChar.b_lh_max}
                              value={fChar.b_lh}
                              step={fChar.b_lh_step}
                              className="line-height form-range"
                              aria-label="Line Height"
                              onChange={(event) =>
                                onLineHeightChangeBigChar(fChar.sequence, event)
                              }
                            ></input>
                          </div>
                          <div
                            className={
                              fChar.overflow
                                ? "align-unit d-none"
                                : "align-unit"
                            }
                          >
                            <button
                              type="button"
                              name={fChar.sequence}
                              className="btn btn-link text-left"
                              onClick={() =>
                                onAddTextLeftBigChar(fChar.sequence)
                              }
                            >
                              <i className="bi bi-text-left"></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-center"
                              onClick={() =>
                                onAddTextCenterBigChar(fChar.sequence)
                              }
                            >
                              <i className="bi bi-text-center"></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className="btn btn-link text-right"
                              onClick={() =>
                                onAddTextRightBigChar(fChar.sequence)
                              }
                            >
                              <i className="bi bi-text-right"></i>
                            </button>
                          </div>
                          <div className="tools">
                            <button
                              type="button"
                              name="align"
                              className={
                                fChar.overflow
                                  ? "btn btn-link text-tools"
                                  : "btn btn-link text-tools d-none"
                              }
                              onClick={() => onRemoveOverflowChar(i)}
                            >
                              <i className="bi bi-tools"></i>
                            </button>
                            <button
                              type="button"
                              name="align"
                              className={
                                fChar.overflow
                                  ? "btn btn-link text-arrows d-none"
                                  : "btn btn-link text-arrows"
                              }
                              onClick={() => onAddOverflowChar(i)}
                            >
                              <i className="bi bi-arrows-angle-contract"></i>
                            </button>
                          </div>
                        </div>
                        <div className="options-unit">
                          <button
                            type="button"
                            className="btn btn-link apply-all"
                            onClick={() => onApplyAllBigChar(fChar.sequence)}
                          >
                            Apply All
                          </button>
                          <button
                            type="button"
                            className="btn btn-link reset-all"
                            onClick={onResetAllBig}
                          >
                            Reset All
                          </button>
                        </div>
                      </div>
                      <div
                        className={
                          fChar.overflow
                            ? "weight-unit d-flex d-none"
                            : "weight-unit d-flex"
                        }
                      >
                        <div className="weight">Weight (wght)</div>
                        <div className="size-unit">
                          <input
                            type="range"
                            name="wght"
                            min={fChar.b_wght_min}
                            max={fChar.b_wght_max}
                            value={fChar.b_wght}
                            step={fChar.b_wght_step}
                            className="size form-range"
                            aria-label="Wght"
                            onChange={(event) => onWghtChangeBigChar(i, event)}
                          ></input>
                        </div>
                      </div>
                      <div
                        className="text-unit"
                        onClick={() => onRemoveOverflowChar(i)}
                        onKeyDown={() => onRemoveOverflowChar(i)}
                        role="none"
                      >
                        <p
                          contentEditable="true"
                          spellCheck="false"
                          suppressContentEditableWarning="true"
                          className={
                            fChar.overflow
                              ? "overflow " +
                                fChar.font_class +
                                " " +
                                fChar.b_alignment
                              : fChar.font_class + " " + fChar.b_alignment
                          }
                          style={{
                            fontSize: fChar.b_size + "em",
                            lineHeight: fChar.b_lh + "em",
                            fontVariationSettings: "'wght'" + fChar.b_wght,
                          }}
                          onBlur={(event) =>
                            onTextChangeBigChar(fChar.sequence, event)
                          }
                          role="none"
                        >
                          {fChar.b_text}
                        </p>
                      </div>
                    </form>
                  </Col>
                  {/* Small Char */}
                  <Col className="unit-small char-border d-md-none" md={12}>
                    <div className="tester-nav">
                      <div className="d-flex justify-content-between">
                        <div className="weight-unit">
                          <div className="system">Basic Character Set</div>
                        </div>
                        <div className="options-unit">
                          <div>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => toggleShowOptChar(fChar.sequence)}
                            >
                              Options
                            </button>
                          </div>
                          <Offcanvas
                            show={fChar.itemShowOptChar}
                            onHide={() => handleCloseOptChar(fChar.sequence)}
                            backdrop={backdropShowOpt}
                            scroll={bodyScrollOpt}
                            placement={placementOpt}
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                {fChar.name} Options
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-link text-left"
                                  onClick={() =>
                                    onAddTextLeftSmallChar(fChar.sequence)
                                  }
                                >
                                  <i
                                    className="bi bi-text-left"
                                    style={{ fontSize: "2em" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link text-center"
                                  onClick={() =>
                                    onAddTextCenterSmallChar(fChar.sequence)
                                  }
                                >
                                  <i
                                    className="bi bi-text-center"
                                    style={{ fontSize: "2em" }}
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link text-right"
                                  onClick={() =>
                                    onAddTextRightSmallChar(fChar.sequence)
                                  }
                                >
                                  <i
                                    className="bi bi-text-right"
                                    style={{ fontSize: "2em" }}
                                  ></i>
                                </button>
                              </div>
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-link apply-all"
                                  onClick={() =>
                                    onApplyAllSmallChar(fChar.sequence)
                                  }
                                >
                                  Apply to All
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link reset-all"
                                  onClick={onResetAllSmall}
                                >
                                  Reset All
                                </button>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        </div>
                      </div>
                      <div className="size-unit">
                        <input
                          type="range"
                          name="size"
                          min={fChar.s_size_min}
                          max={fChar.s_size_max}
                          value={fChar.s_size}
                          step={fChar.s_size_step}
                          className="size form-range"
                          aria-label="Size"
                          onChange={(event) =>
                            onSizeChangeSmallChar(fChar.sequence, event)
                          }
                        ></input>
                      </div>
                      <div className="size-unit">
                        <input
                          type="range"
                          name="wght"
                          min={fChar.s_wght_min}
                          max={fChar.s_wght_max}
                          value={fChar.s_wght}
                          step={fChar.s_wght_step}
                          className="size form-range"
                          aria-label="Wght"
                          onChange={(event) => onWghtChangeSmallChar(i, event)}
                        ></input>
                      </div>
                    </div>
                    <div className="text-unit">
                      <p
                        contentEditable="true"
                        spellCheck="false"
                        suppressContentEditableWarning="true"
                        className={fChar.font_class + " " + fChar.s_alignment}
                        style={{
                          fontSize: fChar.s_size + "em",
                          lineHeight: fChar.s_lh + "em",
                          whiteSpace: "pre-line",
                          fontVariationSettings: "'wght'" + fChar.s_wght,
                        }}
                        onBlur={(event) =>
                          onTextChangeSmallChar(fChar.sequence, event)
                        }
                        role="none"
                      >
                        {fChar.s_text}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </Container>
      </FadeIn>
    </>
  );
}

export default FuquaVar;
