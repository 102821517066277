import { Container, Row, Col } from "react-bootstrap";

function Word({ font }) {
  return (
    <Container fluid id="word" className="fonts-words">
      {font.words.map((word, i) => (
        <Row key={i}>
          <Col md={12} className="fonts-image-col">
            <p
              className={word.font_class}
              style={{
                fontSize: word.size + "vw",
                lineHeight: word.lh + "em",
                fontVariantLigatures: "discretionary-ligatures",
              }}
            >
              {word.text}
            </p>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default Word;
