import { Container, Row, Col } from "react-bootstrap";

function Use({ font }) {
  return (
    <Container fluid id="use" className="fonts-use">
      <Row>
        <Col md={6} className="fonts-image-col mt-4">
          <div className="spec">
            <img
              src={"/images/font/" + font.slug + "/" + font.slug + "-use-1.png"}
              className="img-fluid scale"
              alt={font.slug + "-use-1"}
            />
          </div>
        </Col>
        <Col md={6} className="fonts-image-col mt-4">
          <div className="spec">
            <img
              src={"/images/font/" + font.slug + "/" + font.slug + "-use-2.png"}
              className="img-fluid scale"
              alt={font.slug + "-use-2"}
            />
          </div>
        </Col>
        <Col md={6} className="fonts-image-col mt-4">
          <div className="spec">
            <img
              src={"/images/font/" + font.slug + "/" + font.slug + "-use-3.png"}
              className="img-fluid scale"
              alt={font.slug + "-use-3"}
            />
          </div>
        </Col>
        <Col md={6} className="fonts-image-col mt-4">
          <div className="spec">
            <img
              src={"/images/font/" + font.slug + "/" + font.slug + "-use-4.png"}
              className="img-fluid scale"
              alt={font.slug + "-use-4"}
            />
          </div>
        </Col>
      </Row>
      {font.squse && (
        <Row>
          <Col md={4} className="fonts-image-col mt-4">
            <div className="spec">
              <img
                src={
                  "/images/font/" +
                  font.slug +
                  "/" +
                  font.slug +
                  "-use-sq-1.png"
                }
                className="img-fluid scale"
                alt={font.slug + "-use-4"}
              />
            </div>
          </Col>
          <Col md={4} className="fonts-image-col mt-4">
            <div className="spec">
              <img
                src={
                  "/images/font/" +
                  font.slug +
                  "/" +
                  font.slug +
                  "-use-sq-2.png"
                }
                className="img-fluid scale"
                alt={font.slug + "-use-4"}
              />
            </div>
          </Col>
          <Col md={4} className="fonts-image-col mt-4">
            <div className="spec">
              <img
                src={
                  "/images/font/" +
                  font.slug +
                  "/" +
                  font.slug +
                  "-use-sq-3.png"
                }
                className="img-fluid scale"
                alt={font.slug + "-use-4"}
              />
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Use;
