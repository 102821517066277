import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

function Info({ font }) {
  // State Etc
  const [designers, setDesigners] = useState([]);
  const [variations, setVariations] = useState([]);
  const [relations, setRelations] = useState([]);

  // useEffects
  useEffect(() => {
    setDesigners(
      font.designers.map((item) => ({
        ...item,
      }))
    );
  }, [font.designers]);

  useEffect(() => {
    setVariations(
      font.variations.map((item) => ({
        ...item,
      }))
    );
  }, [font.variations]);

  useEffect(() => {
    setRelations(
      font.relations.map((item) => ({
        ...item,
      }))
    );
  }, [font.relations]);

  return (
    <Container id="info" className="fonts-info">
      <Row>
        <Col md={12}>
          <p className="font-name">{font.name}</p>
        </Col>
        {designers.length === 1 ? (
          <Col md={12}>
            {designers
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((designer, i) => (
                <p key={i} className="desc-status">
                  Designer:&nbsp;
                  <a href={designer.link} target="_blank" rel="noreferrer">
                    {designer.name}
                  </a>
                </p>
              ))}
          </Col>
        ) : (
          <Col md={12}>
            {designers
              .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
              .map((designer, i) => (
                <p key={i} className="desc-status">
                  Designers:&nbsp;
                  <a href={designer.link} target="_blank" rel="noreferrer">
                    {designer.name}
                  </a>
                </p>
              ))}
          </Col>
        )}
        <Col md={12}>
          <p className="desc-status">Version: {font.version}</p>
        </Col>
        <Col md={12}>
          <p className="desc-status">
            Tier: <Link to="/tiers">{font.tier}</Link>
          </p>
        </Col>
        {font.pdf && (
          <Col md={12}>
            <p className="desc-status">
              Download Specimen:{" "}
              <a href={"/pdfs/spec/" + font.slug + "/" + font.pdf}>
                {font.name} Specimen
              </a>
            </p>
          </Col>
        )}
        {font.desc && (
          <Col md={8}>
            <p className="desc-status">{font.desc}</p>
          </Col>
        )}
        {font.inclusion && (
          <Col md={12}>
            <p className="desc-status">{font.inclusion}</p>
          </Col>
        )}
        <div className="mt-2">
          {variations.length > 0 && (
            <Col md={12}>
              <DropdownButton
                id="dropdown-basic-button"
                title={variations.length + " Variable Font(s)"}
                className="relation-button"
              >
                {variations
                  .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                  .map((variation, i) => (
                    <Dropdown.Item
                      key={i}
                      href={"/font/" + font.slug + "-variable/"}
                      className="relation-button"
                    >
                      {variation.name}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </Col>
          )}
          {relations.length > 0 && (
            <Col md={12}>
              <DropdownButton
                id="dropdown-basic-button"
                title={relations.length + " Related Font(s)"}
                className="relation-button"
              >
                {relations
                  .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
                  .map((relation, i) => (
                    <Dropdown.Item
                      key={i}
                      href={"/font/" + relation.slug}
                      className="relation-button"
                    >
                      {relation.name}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </Col>
          )}
        </div>
      </Row>
    </Container>
  );
}

export default Info;
