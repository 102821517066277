import "./assets/scss/style.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HashLink as Link } from "react-router-hash-link";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import CookieConsent from "react-cookie-consent";

import Navigation from "./components/main/Navigation";
import Footer from "./components/main/Footer";

import CatalogDetails from "./components/detail/CatalogDetails";
import FuquaVarDetail from "./components/detail/FuquaVarDetail";
import AdoreMonoDetail from "./components/detail/AdoreMonoDetail";
import ArbuckleDetail from "./components/detail/ArbuckleDetail";
import HomageCondDetail from "./components/detail/HomageCondDetail";
import HomageScriptDetail from "./components/detail/HomageScriptDetail";
import PipelineDetail from "./components/detail/PipelineDetail";
import AllDetail from "./components/detail/AllDetail";
import Stack from "./components/section/Stack";

import Home from "./components/home/Home";
import Pipe from "./components/home/Pipe";
import Collection from "./components/font/Collection";
import Font from "./components/font/Font";
// import FuquaVar from "./components/variation/FuquaVar";
import About from "./components/about/About";
import Foundry from "./components/about/Foundry";
import Contact from "./components/about/Contact";
import Colophon from "./components/about/Colophon";
import Support from "./components/support/Support";
import Licenses from "./components/support/Licenses";
import Eula from "./components/support/Eula";
import Tiers from "./components/support/Tiers";
import Discounts from "./components/support/Discounts";
import Services from "./components/support/Services";
import Policies from "./components/support/Policies";
import Blog from "./components/blog/Blog";
import Blog0001 from "./components/blog/Blog0001";
import Blog0002 from "./components/blog/Blog0002";
import Blog0003 from "./components/blog/Blog0003";
import Grid from "./components/section/Grid";
import Spec from "./components/section/Spec";
import Catalog from "./components/section/Catalog";
import PipeSpec from "./components/section/PipeSpec";
import AdobeFonts from "./components/fbakery/AdobeFonts";
import NotFound from "./components/section/NotFound";
// import Redirect from "./components/section/Redirect";
import Cart from "./components/cart/Cart";
import Login from "./components/account/Login";
import LoginCheckout from "./components/account/LoginCheckout";
import LoginTrial from "./components/account/LoginTrial";
import Register from "./components/account/Register";
import ActivateSent from "./components/account/ActivateSent";
import ActivateInit from "./components/account/ActivateInit";
import ActivateComplete from "./components/account/ActivateComplete";
import ResetSubmit from "./components/account/ResetSubmit";
import ResetSent from "./components/account/ResetSent";
import ResetInit from "./components/account/ResetInit";
import ResetComplete from "./components/account/ResetComplete";
import Profile from "./components/account/Profile";
import ChangeDetails from "./components/account/ChangeDetails";
import ChangePassword from "./components/account/ChangePassword";
import Download from "./components/account/Download";
import BillingInfo from "./components/checkout/BillingInfo";
import LicenseHolder from "./components/checkout/LicenseHolder";
import PaymentMethod from "./components/checkout/PaymentMethod";
import PayPalIntent from "./components/checkout/PayPalIntent";
import StripeIntent from "./components/checkout/StripeIntent";
import Complete from "./components/checkout/Complete";
import OrderError from "./components/checkout/OrderError";
import TrialLicenseHolder from "./components/checkout/TrialLicenseHolder";
import TrialIntent from "./components/checkout/TrialIntent";
import TrialComplete from "./components/checkout/TrialComplete";
import Invoice from "./components/account/Invoice";
import OrderDetails from "./components/mgmt/OrderDetails";

const payPalOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
};

function App() {
  return (
    <PayPalScriptProvider options={payPalOptions}>
      <BrowserRouter>
        <Helmet>
          <title>GarageFonts Type Foundry</title>
        </Helmet>
        <Navigation />
        <Routes>
          <Route path="/details" element={<CatalogDetails />} />
          <Route path="/detail/fuqua-variable" element={<FuquaVarDetail />} />
          <Route path="/detail/adore-mono" element={<AdoreMonoDetail />} />
          <Route path="/detail/arbuckle" element={<ArbuckleDetail />} />
          <Route
            path="/detail/homage-condensed"
            element={<HomageCondDetail />}
          />
          <Route
            path="/detail/homage-script"
            element={<HomageScriptDetail />}
          />
          <Route path="/detail/pipeline" element={<PipelineDetail />} />
          <Route path="/fonts-in-use" element={<AllDetail />} />
          <Route path="/fonts-2024" element={<Stack />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pipeline" element={<Pipe />} />
          <Route path="/collection/:slug" element={<Collection />} />
          <Route path="/fonts" element={<Catalog />} />
          <Route path="/font/:slug" element={<Font />} />
          <Route path="/about" element={<About />} />
          <Route path="/foundry" element={<Foundry />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/colophon" element={<Colophon />} />
          <Route path="/support" element={<Support />} />
          <Route path="/licenses" element={<Licenses />} />
          <Route path="/eula" element={<Eula />} />
          <Route path="/tiers" element={<Tiers />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/services" element={<Services />} />
          <Route path="/policies" element={<Policies />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/a-garagefonts-transition" element={<Blog0001 />} />
          <Route path="/blog/font-tiers" element={<Blog0002 />} />
          <Route path="/blog/fuqua-release" element={<Blog0003 />} />
          <Route path="/gallery" element={<Grid />} />
          <Route path="/specimens" element={<Spec />} />
          <Route path="/pipeline-specs" element={<PipeSpec />} />
          <Route path="/fontbakery/reports" element={<AdobeFonts />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/activate-sent" element={<ActivateSent />} />
          <Route path="/activate/:uid/:token" element={<ActivateInit />} />
          <Route path="/activate-complete" element={<ActivateComplete />} />
          <Route path="/reset-request" element={<ResetSubmit />} />
          <Route path="/reset-sent" element={<ResetSent />} />
          <Route path="/reset/:uid/:token" element={<ResetInit />} />
          <Route path="/reset-complete" element={<ResetComplete />} />
          <Route path="/account/profile" element={<Profile />} />
          <Route path="/account/change-details" element={<ChangeDetails />} />
          <Route path="/account/change-password" element={<ChangePassword />} />
          <Route
            path="/account/download/:name/:slug/:file"
            element={<Download />}
          />
          <Route path="/account/invoice/:orderId" element={<Invoice />} />
          <Route path="/checkout/billing-info" element={<BillingInfo />} />
          <Route path="/checkout/license-holder" element={<LicenseHolder />} />
          <Route path="/checkout/payment-method" element={<PaymentMethod />} />
          <Route path="/checkout/login" element={<LoginCheckout />} />
          <Route path="/checkout/payment-paypal" element={<PayPalIntent />} />
          <Route path="/checkout/payment-cc" element={<StripeIntent />} />
          <Route path="/checkout/complete" element={<Complete />} />
          <Route path="/checkout/order-error" element={<OrderError />} />
          <Route
            path="/checkout/trial/license-holder"
            element={<TrialLicenseHolder />}
          />
          <Route path="/checkout/trial/login" element={<LoginTrial />} />
          <Route
            path="/checkout/trial/submit-order"
            element={<TrialIntent />}
          />
          <Route path="/checkout/trial/complete" element={<TrialComplete />} />
          <Route path="/admin/order/:orderId" element={<OrderDetails />} />
        </Routes>
        <Footer />
        <CookieConsent
          style={{ background: "#333333", color: "white" }}
          buttonStyle={{ background: "white", color: "black" }}
        >
          This website uses cookies to enhance the user experience.{" "}
          <Link to="/policies">More about our privacy and cookies policy.</Link>
        </CookieConsent>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
