import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { listFontDetail } from "../../actions/fontActions";
import { listCalcs } from "../../actions/calcActions";
import { useParams } from "react-router-dom";
import Loader from "../main/Loader";
import Message from "../main/Message";
import Name from "./Name";
import Square from "./Square";
import FuquaVar from "../variation/FuquaVar";
import Tester from "./Tester";
import Info from "./Info";
import Word from "./Word";
import Use from "./Use";
import Specimen from "./Specimen";
import Calc from "./Calc";

function Font() {
  // Selectors
  const fontDetail = useSelector((state) => state.fontDetail);
  const { error, loading, font } = fontDetail;

  const calcList = useSelector((state) => state.calcList);
  const { calcs } = calcList;

  // Dispatch and Navigate
  const dispatch = useDispatch();

  // State Etc
  const { slug } = useParams();
  const [scheme, setScheme] = useState(slug);
  const [slugDisplay, setSlugDisplay] = useState("d-block");
  const [sectionDisplay, setSectionDisplay] = useState("d-none");
  const [homeDisplay, setHomeDisplay] = useState("d-none");

  // Section Change
  const onSchemeChange = (i) => {
    setScheme(i);
  };

  useEffect(() => {
    if (scheme === "section") {
      setSlugDisplay("d-none");
      setSectionDisplay("d-block");
      setHomeDisplay("d-none");
    } else if (scheme === "home") {
      setSlugDisplay("d-none");
      setSectionDisplay("d-none");
      setHomeDisplay("d-block");
    } else {
      setSlugDisplay("d-block");
      setSectionDisplay("d-none");
      setHomeDisplay("d-none");
    }
  }, [scheme, slug]);

  // useEffects
  useEffect(() => {
    dispatch(listFontDetail(slug));
    dispatch(listCalcs());
  }, [dispatch, slug]);

  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top " +
      scheme +
      "-bg";
  }, [scheme]);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top " +
      scheme +
      "-bg";
  }, [scheme]);

  useEffect(() => {
    const nav = document.querySelector(".work-nav");
    nav.className = "work-nav fixed-top " + scheme + "-bg";
  }, [scheme]);

  useEffect(() => {
    const footer = document.querySelector(".footer-contain");
    footer.className = "footer-contain " + scheme + "-bg";
  }, [scheme]);

  return (
    <>
      <Helmet>
        <body className={scheme + "-bg"} />
      </Helmet>
      <FadeIn>
        <div className="d-print-none">
          <Container fluid className="work-nav fixed-top">
            <div className="d-md-flex d-none justify-content-between">
              <div className="d-flex align-items-center">
                <div>
                  <a href="#name">{font.name}</a>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <a href="#tester">Test</a>
                </div>
                <div className="work-item">
                  <a href="#info">Info</a>
                </div>
                <div className="work-item">
                  <a href="#use">Use</a>
                </div>
                <div className={slugDisplay + " work-item-scheme"}>
                  <button
                    type="button"
                    name={slug + " Scheme"}
                    className="btn btn-link scheme-circle"
                    onClick={() => onSchemeChange("section")}
                  >
                    <i className="bi bi-circle-half"></i>
                  </button>
                </div>
                <div className={sectionDisplay + " work-item-scheme"}>
                  <button
                    type="button"
                    name="Section scheme"
                    className="btn btn-link scheme-circle"
                    onClick={() => onSchemeChange("home")}
                  >
                    <i className="bi bi-circle-half"></i>
                  </button>
                </div>
                <div className={homeDisplay + " work-item-scheme"}>
                  <button
                    type="button"
                    name="Home scheme"
                    className="btn btn-link scheme-circle"
                    onClick={() => onSchemeChange([slug])}
                  >
                    <i className="bi bi-circle-half"></i>
                  </button>
                </div>
                {font.active && (
                  <div className="work-item">
                    <Button className="btn buy-button" href="#buy">
                      Buy
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex d-md-none justify-content-center">
              <div className="d-flex align-items-center">
                <div className="work-item">
                  <a href="#tester">Test</a>
                </div>
                <div className="work-item">
                  <a href="#info">Info</a>
                </div>
                <div className="work-item">
                  <a href="#use">Use</a>
                </div>
                <div className={slugDisplay + " work-item-scheme"}>
                  <button
                    type="button"
                    name={slug + " Scheme"}
                    className="btn btn-link scheme-circle"
                    onClick={() => onSchemeChange("section")}
                  >
                    <i className="bi bi-circle-half"></i>
                  </button>
                </div>
                <div className={sectionDisplay + " work-item-scheme"}>
                  <button
                    type="button"
                    name="Section scheme"
                    className="btn btn-link scheme-circle"
                    onClick={() => onSchemeChange("home")}
                  >
                    <i className="bi bi-circle-half"></i>
                  </button>
                </div>
                <div className={homeDisplay + " work-item-scheme"}>
                  <button
                    type="button"
                    name="Home scheme"
                    className="btn btn-link scheme-circle"
                    onClick={() => onSchemeChange([slug])}
                  >
                    <i className="bi bi-circle-half"></i>
                  </button>
                </div>
                {font.active && (
                  <div className="work-item">
                    <Button className="btn buy-button" href="#buy">
                      Buy
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </FadeIn>
      <Container fluid className="fonts-contain">
        {loading ? (
          <Loader />
        ) : error ? (
          <Row className="message-contain">
            <Col md={12}>
              <Message variant="danger">{error}</Message>
            </Col>
          </Row>
        ) : (
          <>
            <Name font={font} />
            {font.square && <Square font={font} scheme={scheme} />}
            {font.slug === "fuqua-variable" && <FuquaVar font={font} />}
            {!font.var && <Tester font={font} />}
            {/* <Tester font={font} /> */}
            <Info font={font} />
            {font.word && <Word font={font} />}
            {font.inuse && <Use font={font} />}
            {font.specimen && <Specimen font={font} scheme={scheme} />}
            {font.active && <Calc font={font} calcs={calcs} />}
          </>
        )}
      </Container>
    </>
  );
}

export default Font;
