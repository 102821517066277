import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import FadeIn from "react-fade-in";

function Name({ font }) {
  return (
    <Container fluid id="name" className="fonts-top">
      <Helmet>
        <title>{`${font.name} - GarageFonts`}</title>
      </Helmet>
      <FadeIn>
        <Row>
          <Col md={12}>
            <p className="font-name">{font.name}</p>
          </Col>
        </Row>
      </FadeIn>
    </Container>
  );
}

export default Name;
