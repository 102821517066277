import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function PipelineDetail() {
  //Color Scheme
  useEffect(() => {
    const nav = document.querySelector(".navigation-large");
    nav.className =
      "navigation-large d-none d-md-flex justify-content-between fixed-top section-bg";
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navigation-small");
    nav.className =
      "navigation-small d-flex justify-content-between d-md-none fixed-top section-bg";
  }, []);

  return (
    <>
      <Helmet>
        <body className="section-bg" />
      </Helmet>
      <Container fluid className="fonts-contain">
        <>
          <Container>
            <Helmet>
              <title>{`Adore Mono - GarageFonts`}</title>
            </Helmet>
            <FadeIn>
              <Row>
                <Col md={8}>
                  <p className="detail-name">Pipeline</p>
                  <p className="desc-status">
                    We currently have a long list of fonts in the pipeline. As
                    one of the oldest operating digital type foundries in the
                    world, GarageFonts has had a long history of releasing
                    unique display typeface designs, most of which were designed
                    sometime in the 1990s. The original GarageFonts are not only
                    historic and groundbreaking but also highly expressive,
                    experimental, and fun. Fonts in the pipeline include designs
                    from this previous generation of GarageFonts being prepared
                    for re-release and brand new designs as well.
                  </p>
                  <p className="desc-status">
                    Fonts we are currently optimizing include:{" "}
                    <Link to="/font/archive" className="info-nav">
                      Archive
                    </Link>{" "}
                    and the{" "}
                    <Link to="/font/nice-weekend" className="info-nav">
                      Weekend Suite
                    </Link>{" "}
                    by Pieter van Rosmalen,{" "}
                    <Link to="/font/clarice" className="info-nav">
                      Clarice
                    </Link>{" "}
                    by Robby Woodard,{" "}
                    <Link to="/font/fono" className="info-nav">
                      Fono
                    </Link>{" "}
                    by Thomas Mettendorf,{" "}
                    <Link to="/font/kienan" className="info-nav">
                      Kienan
                    </Link>{" "}
                    by Phil's Fonts, and{" "}
                    <Link to="/font/karazan" className="info-nav">
                      Karazan
                    </Link>{" "}
                    and{" "}
                    <Link to="/font/klif" className="info-nav">
                      Klif
                    </Link>{" "}
                    by Lionel Barat.
                  </p>
                  <p className="desc-status">
                    <a href="/details">Back to details</a>
                  </p>
                </Col>
              </Row>
            </FadeIn>
          </Container>
          <Container className="fonts-detail" fluid>
            <Row className="mt-5">
              <Col md={4} className="fonts-image-col">
                <Link to="/font/archive" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/pipeline/archive-story-2.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/clarice" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/clarice-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col">
                <Link to="/font/dotted-weekend" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/dotted-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/kamaro" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/kamaro-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/fono" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/pipeline/fono-story-1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/karazan" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/karazan-story-2.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/klif" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/klif-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/kienan" className="info-nav">
                  <div className="spec">
                    <img
                      src="/images/detail/pipeline/kienan-story-1.png"
                      className="img-fluid"
                      alt="detail-2"
                    />
                  </div>
                </Link>
              </Col>
              <Col md={4} className="fonts-image-col mt-4">
                <Link to="/font/nice-weekend" className="info-nav">
                  <div className="spec">
                    <video class="w-100" controls autoPlay muted loop>
                      <source
                        src="/images/detail/pipeline/weekend-story-1.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      </Container>
    </>
  );
}

export default PipelineDetail;
