import { Container, Row, Col } from "react-bootstrap";

function Specimen({ font, scheme }) {
  return (
    <Container className="fonts-spec">
      <Row>
        <Col md={4} className="fonts-image-col">
          <div className="image-square">
            <a
              href={
                "/pdfs/spec/" + font.slug + "/" + font.slug + "-specimen.pdf"
              }
            >
              <img
                src={
                  "/images/spec/" +
                  font.slug +
                  "/" +
                  scheme +
                  "/" +
                  font.slug +
                  "-specimen-1.png"
                }
                className="img-fluid spec grid"
                alt={font.slug + "-specimen"}
              />
            </a>
          </div>
        </Col>
        <Col md={4} className="fonts-image-col">
          <div className="image-square">
            <a
              href={
                "/pdfs/spec/" + font.slug + "/" + font.slug + "-specimen.pdf"
              }
            >
              <img
                src={
                  "/images/spec/" +
                  font.slug +
                  "/" +
                  scheme +
                  "/" +
                  font.slug +
                  "-specimen-2.png"
                }
                className="img-fluid spec grid"
                alt={font.slug + "-specimen"}
              />
            </a>
          </div>
        </Col>
        <Col md={4} className="fonts-image-col">
          <div className="image-square">
            <a
              href={
                "/pdfs/spec/" + font.slug + "/" + font.slug + "-specimen.pdf"
              }
            >
              <img
                src={
                  "/images/spec/" +
                  font.slug +
                  "/" +
                  scheme +
                  "/" +
                  font.slug +
                  "-specimen-3.png"
                }
                className="img-fluid spec grid"
                alt={font.slug + "-specimen"}
              />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Specimen;
